import "./App.css";
import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
// import dayjs from 'dayjs';
// import axios from 'axios';

function App() {

  return (
    <>
    <Router>
      <Routes />
    </Router>
    </>
  );
}

export default App;
