import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_data: null,
  stock_data: null,
};
// const initialState = [];
const canvasSlice = createSlice({
  name: "canvasSlice",
  initialState,
  reducers: {
    setCanvases(state, action) {
        // console.log('from canvas store',action)
      state.user_data = action.payload.user_data;
      state.stock_data = action.payload.stock_data;
      // state.user_data = action.payload;
    },
    addCanvas: (state, action) => {
      state.user_data.push(action.payload);
    },
    updateCanvas: (state, action) => {
      const temp = state.user_data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.user_data = temp;
    },
    deleteCanvas(state, action) {

      state.user_data = [...state.user_data];
      const indexToBeDeleted = action.payload;
      const tempState = [...state.user_data];
      tempState.splice(indexToBeDeleted, 1);
      state.user_data = tempState;
      console.log(" data from template store", state.user_data);
    },
  },
});

export const { setCanvases, addCanvas, updateCanvas, deleteCanvas } =
canvasSlice.actions;
export default canvasSlice.reducer;
