import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: '',
  collapsed: true,
  sidebarSwitch : false,
};

const sidebarStatus = createSlice({
  name: "sidebarStatus",
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload.status
    },
    setCollapsed(state, action) {
      state.collapsed = action.payload
    },
    setSidebarSwitch(state, action) {
      state.sidebarSwitch = action.payload.status
    },
  },
});

export const { setStatus, setCollapsed, setSidebarSwitch } = sidebarStatus.actions;
export default sidebarStatus.reducer;
