import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedItem: null,
};

const screenContentModalSelectedItemSlice = createSlice({
  name: "screenContentModalSelectedItemSlice",
  initialState,
  reducers: {
    setSelectedItemForScreen(state, action) {
      state.selectedItem = action.payload;
     
    },
  },
});

export const { setSelectedItemForScreen  } = screenContentModalSelectedItemSlice.actions;
export default screenContentModalSelectedItemSlice.reducer;
