import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlineStatus :{}
};

const onlineStatusDetails = createSlice({
  name: "onlineStatusDetails",
  initialState,
  reducers: {
    setOnlineStatus(state, action) {
      state.onlineStatus = action.payload;
    },
  },
});

export const { setOnlineStatus } = onlineStatusDetails.actions;
export default onlineStatusDetails.reducer;
