import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const profileDetails = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    addProfileDetails: (state, action) => {
    
      return { ...state, ...action.payload };

    },
    updateProfileDetails: (state, action) => {
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.avatar = action.payload.avatar;
      state.last_name = action.payload.last_name;
    },
  },
});

export const { addProfileDetails } = profileDetails.actions;
export default profileDetails.reducer;
