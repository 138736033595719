import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const templateDetailsUpdateSlice = createSlice({
  name: "templateDetails",
  initialState,
  reducers: {
    added(state, action) {
      // state.push(action.payload)
console.log('from template store',...action.payload)
console.log('from action.payload',)
      return action.payload;
    },
    updateTemplateDetails: (state, action) => {
      console.log("updateTemplateDetails", action.payload);
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteTemplateDetails: (state, action) => {
        return state.filter((item) => item.id !== action.payload.id);
      },
  },
});

export const { added, deleteTemplateDetails,updateTemplateDetails } = templateDetailsUpdateSlice.actions;
export default templateDetailsUpdateSlice.reducer;
