import { createSlice } from "@reduxjs/toolkit";
import { Input, InputNumber } from "antd";

const initialState = {
  divState: [],
  draggerId: [],
  screenSize: {
    width: 0,
    height: 0,
  },
};

const devJson = {
  id: "",
  div_name: "",
  position: "absolute",
  marginTop: 0,
  marginLeft: 0,
  height: 1080,
  width: 1920,
  backgroundColor: "#ffffff",
  border: 0,
  borderRadius: 0,
  borderColor: "light grey",
  zIndex: 0,
};

const templateCreatorSlice = createSlice({
  name: "headerPartSlicer",
  initialState,
  reducers: {

    initiateWithValue: (state, action) => {
      return {
        divState : action.payload.divState,
        draggerId : action.payload.draggerId,
        screenSize: action.payload.screenSize,

      } 
    },
    setNewDiv: (state, action) => {
      console.log("Dispatched", action.payload);
      // Considering maximum number of divs as 5 and the zIndex is sto be set from 5 to 1
      const tempZIndex = 5 - state.divState.length;

      return {
        divState: [
          ...state.divState,
          { ...devJson, div_name: action.payload, zIndex: tempZIndex },
        ],
        draggerId: [...state.draggerId, action.payload],
        screenSize: state.screenSize,
      };
    },

    setValInDiv: (state, action) => {
      const newDiv = state.divState.map((div) => {
        if (div.div_name === action.payload.divName) {
          return { ...div, [action.payload.inputType]: action.payload.value };
        } else {
          return div;
        }
      });

      return {
        divState: newDiv,
        draggerId: state.draggerId,
        screenSize: state.screenSize,
      };
    },
    setZIndex: (state, action) => {
      const newDiv = state.divState.map((div) => {
        if (div.div_name === action.payload.divName) {
          return { ...div, zIndex: action.payload.destinationIndex };
        }
        // else if (div.zIndex === action.payload.destinationIndex){
        //   return {...div, zIndex: action.payload.sourceIndex}
        // }
        else {
          const zindex = div.zIndex + action.payload.mavingFactor;
          return { ...div, zIndex: zindex };
        }
      });

      return {
        divState: newDiv,
        draggerId: state.draggerId,
        screenSize: state.screenSize,
      };
    },
    deleteDiv: (state, action) => {
      const tempIndexDiv = state.divState.map((div, index) => {
        if (div.div_name === action.payload.divName) {
          return index;
        }
      });
      const tempIndexDraggerId = state.draggerId.map((div, index) => {
        if (div.div_name === action.payload.divName) {
          return index;
        }
      });
      const newState = state;
      newState.draggerId.splice(tempIndexDiv, 1);
      newState.divState.splice(tempIndexDraggerId, 1);
      return newState;
    },
    screenUpdate: (state, action) => {
      return ({
        divState: state.divState,
        draggerId: state.draggerId,
        screenSize: action.payload
      })
    }
  },
});

export const { setNewDiv, setValInDiv, setZIndex, deleteDiv, screenUpdate, initiateWithValue } =
  templateCreatorSlice.actions;
export default templateCreatorSlice.reducer;
