import { createSlice } from "@reduxjs/toolkit";

const initialState = {'halo':'kalo'};

const playlistDetailsSlice = createSlice({
  name: "playlistDetailsSlice",
  initialState,
  reducers: {
    setPlaylist(state, action) {
        // console.log('this is from store', action.payload)
      state.halo = action.payload.id
    },
  },
});

export const { setPlaylist } = playlistDetailsSlice.actions;
export default playlistDetailsSlice.reducer;
