import axios from "axios";
import {baseURL} from './baseURL'
const options = {
  headers: {"content-type": "application/json"}
}

const instance = axios.create({
  baseURL: baseURL+'/',
  timeout: 5000,
  // headers: {"content-type": "application/json"},
});

export default instance;