import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_data: null,
  stock_data:null
};
// const initialState = [];
const templateSlice = createSlice({
  name: "templateSlice",
  initialState,
  reducers: {
    setTemplates(state, action) {
      state.user_data = action.payload.user_data;
      state.stock_data = action.payload.stock_data;

    },
    addTemplate: (state, action) => {
      console.log('from template store',action.payload)
      state.user_data.push(action.payload);
    },
    updateTemplate: (state, action) => {
    
      state.user_data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteTemplate(state, action) {
  
      const indexToBeDeleted = action.payload
      const tempState = [...state.user_data];
      tempState.splice(indexToBeDeleted, 1)
      state.user_data = tempState
    },
  },
});

export const { setTemplates, updateTemplate, deleteTemplate, addTemplate} =
templateSlice.actions;
export default templateSlice.reducer;
