import { configureStore } from "@reduxjs/toolkit";
import isAuthenticated from "./isAuthenticated";
import _authToken from "./token";
import profileDetails from "./profileDetails";
import profileImage from "./profileImage";
import userDetails from "./userDetails";
import templateDetails from "./templateDetails";
import headerPart from "./headerPart";
import sidebarStatus from "./sidebarStatus";
import assetDetails from './assetDetails';
// import playlist from './playlist';
import canvasDetails from './canvasDetails';
import templateCreator from "./templateCreator";
import selectedTemplate from "./selectedTemplate";
import playlistDetails from "./playlistDetails";
import bagItems from './bagItems'
import onlineStatusDetails from './onlineStatusDetails'
import screenSlice from './screenSlice'
import screenContentModalSelectedItemSlice from './screenContentModalSelectedItemSlice'
import mediaSlice from './mediaSlice'
import scheduleSlice from './scheduleSlice'
import templateSlice from './templateSlice'
import canvasSlice from './canvasSlice'





const store = configureStore({
  reducer: {
    isAuth: isAuthenticated,
    authToken: _authToken,
    templateDetails: templateDetails,
    profileDetails: profileDetails,
    profileImage: profileImage,
    userDetails: userDetails,
    headerPart: headerPart,
    sidebarStatus: sidebarStatus,
    assetDetails:assetDetails,
    templateCreator: templateCreator,
    selectedTemplate:selectedTemplate,
    playlistDetails:playlistDetails,
    bagItems:bagItems,
    canvasDetails:canvasDetails,
    onlineStatusDetails:onlineStatusDetails,
    screenSlice:screenSlice,
    mediaSlice:mediaSlice,
    scheduleSlice:scheduleSlice,
    templateSlice:templateSlice,
    canvasSlice:canvasSlice,
    screenContentModalSelectedItemSlice:screenContentModalSelectedItemSlice
  },
});

export default store;
