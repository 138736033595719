import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: '',
};

const headerPartSlicer = createSlice({
  name: "headerPartSlicer",
  initialState,
  reducers: {
    setHeader(state, action) {
      state.title = action.payload.title
    },
  },
});

export const { setHeader } = headerPartSlicer.actions;
export default headerPartSlicer.reducer;
