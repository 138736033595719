import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { IntlProvider } from "react-intl";
import english from "./config/translation/locales/en_US.json";
import arabic from "./config/translation/locales/ar_SA.json";
import axios from "axios";
import axiosInstance from "./axiosInstance";
import { baseURL, baseURLRaw } from "./baseURL";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import "@atlaskit/css-reset";
import "lato-font";
import { useHistory } from "react-router-dom";

// function sleep(milliseconds) {
//   const date = Date.now();
//   let currentDate = null;
//   do {
//     currentDate = Date.now();
//   } while (currentDate - date < milliseconds);
// }

// const tenant = localStorage.getItem("tenant");
// if (tenant) {
//   const access_token = localStorage.getItem("access_token");
//   if (access_token) {
//   }
  axios.interceptors.request.use((request) => {
    // request.baseURL = baseURL;
    // console.log('baseURL',baseURL)
    if(baseURL === null){
      const tenant = localStorage.getItem("tenant")
      request.baseURL = baseURLRaw.replace("subdomain", tenant);
    }
    else{
      request.baseURL = baseURL
    }
    request.headers.Authorization =
      "Bearer " + localStorage.getItem("access_token")
    return request;
  });
// }

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale='en' messages={english}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
