import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screens: null,
  screenGroups: null,
  selectedItem: null,
};

const screenSlice = createSlice({
  name: "screenSlice",
  initialState,
  reducers: {
    setScreens(state, action) {
      const sortedList = action.payload.sort((a,b)=>a.id - b.id)
      state.screens = sortedList;
    },
    setScreenGroups(state, action) {
      const sortedList = action.payload.sort((a,b)=>a.id - b.id)
      state.screenGroups = sortedList;
    },
    addScreen(state, action) {
      state.screens = [...state.screens, action.payload];
    },
    deleteScreen(state, action) {
      // state.screens = [...state.screens];
      const indexToBeDeleted = action.payload;
      const tempState = [...state.screens];
      tempState.splice(indexToBeDeleted, 1);
      state.screens = tempState;
    },
    setSelectedContentForScreen(state, action) {
      state.selectedItem = action.payload;
    },
    changeScreenName(state, action) {
      const newState = state.screens.map((item) => {
        if (item.screenId === action.payload.screenId) {
          return { ...item, name: action.payload.newName };
        } else {
          return item;
        }
      });
      state.screens = newState;
    },
    changeScreenGroupName(state, action) {
      const newState = state.screenGroups.map((item) => {
        if (item.id === action.payload.screenGroupId) {
          return { ...item, name: action.payload.newName };
        } else {
          return item;
        }
      });
      state.screenGroups = newState;
    },
    // setItem(state, action) {
    //   console.log("store called", state.screens)
    //   //  Set item is to set an item at index'th position in the state
    //   const index = action.payload.index
    //   const item = action.payload.item
    //   state.screens = state.screens.splice(index, 1, item)
    // }
  },
});

export const {
  setScreens,
  addScreen,
  deleteScreen,
  setScreenGroups,
  changeScreenName,
  changeScreenGroupName,
} = screenSlice.actions;
export default screenSlice.reducer;
