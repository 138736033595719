import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import useWindowSize from "../../../hooks/useWindowSize";
import AnimatedComponent from "./AnimatedComponent";


export default function Screens() {
  const { id } = useParams();
  const [templateDetails, setTemplateDetails] = useState(null);
  const [divs, setDivs] = useState([]);
  const [mediaItems, setMediaItems] = useState(null);
  const window = useWindowSize();
  const [orientation, setOrientation] = useState("Landscape");
  const [scalingFactor, setScalingFactor] = useState(1);

  

  useEffect(() => {
    if (window.width < window.height) {
      setOrientation("Portrait");
    } else {
      setOrientation("Landscape");
    }
    templateDetails !== null ? setScalingFactor(window.width / templateDetails.resolution_width) : setScalingFactor(1)
    // console.log(templateDetails.resolution_width, scalingFactor);
  }, [window, divs]);

  console.log(id);
  useEffect(() => {
    const url = "api/signage/schedules/" + String(id);
    let tempMediaItems = {};
    axios.get(url).then((response) => {
      console.log(response.data);
      setTemplateDetails(response.data.templateclone);
      const divs = response.data.templateclone.divclone.map((item, index) => {
        delete item["schedule_prop"];
        // The below code for creating props for WebSocketComponent
        tempMediaItems[item["id"]] = {};
        // delete item['id']
        delete item["div_name"];
        delete item["template"];
        return item;
      });
      // console.log(divs)
      setDivs(divs);
      setMediaItems(tempMediaItems);
    });
  }, []);
  return (
    <>
      {templateDetails && templateDetails.orientation !== orientation ? (
        <h1>
          The selected template is {templateDetails.orientation} and it will not
          suite for this screen
        </h1>
      ) : (
        <div>
          {divs.map((item, index) => {
            const div_id = item.id;
            console.log("div_id", item.nowplaying);
            const style = new CSSMaker(item, scalingFactor)
            return (
              <div style={style.convertToVirtual()} key={index}>
               <AnimatedComponent templateId = {templateDetails.id} divId = {item.id} nowplaying = {item.nowplaying[0]}/>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}



class CSSMaker {
    constructor(cssObj, virtualizationFactor) {
      this.cssObj = cssObj;
      this.virtualizationFactor = virtualizationFactor;
    }
    convertToVirtual = () => ({
      position : this.cssObj.position,
      marginTop: this.cssObj.marginTop * this.virtualizationFactor,
      marginLeft: this.cssObj.marginLeft * this.virtualizationFactor,
      height: this.cssObj.height * this.virtualizationFactor,
      width: this.cssObj.width * this.virtualizationFactor,
      backgroundColor: this.cssObj.backgroundColor,
      border: this.cssObj.border === String(this.cssObj.border * this.virtualizationFactor) + "px solid",
      borderRadius: this.cssObj.borderRadius,
      borderColor: this.cssObj.border === 0 ? "light grey" : this.cssObj.borderColor,
      zIndex: this.cssObj.zIndex,
    });
  }