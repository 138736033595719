import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const seletedTemplateSlice = createSlice({
  name: "seletedTemplateSlice",
  initialState,
  reducers: {
    setSeletedTemplate: (state, action) => {
        state = action.payload
        return state
    },
  },
});

export const { setSeletedTemplate } = seletedTemplateSlice.actions;
export default seletedTemplateSlice.reducer;
