export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  SIGN_IN_REDIRECTED: "/signin_redirected",
  REGISTRATION: "/registration/:string",
  PRIVACY: "/privacy",
  CONTACT: "/contact",
  DEMO: "/demo",
  LOADING: "/loading",
};
export const PRIVATE_ROUTE = {
  HOME: "/home",
  ASSETS: "/assets",
  GROUPS: "/groups",
  PLAYERS: "/players",
  PLAYLISTS: "/playlists",
  TEMPLATE_DESIGNER: "/templateDesigner",
  SETTINGS:'/settings',
  TEMPLATES:'/templates',
  CANVAS:'/canvas'
};
