import React, { Suspense, lazy } from "react";
import { Switch, Route} from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "./component/utility/loader";
import PrivateRoute from "./PrivateRoute";
import Error404 from "./containers/Pages/Error404/Error404";
import { PUBLIC_ROUTE, PRIVATE_ROUTE } from "./route.constants";
import TestPage1 from "./containers/Pages/TestPage/TestPage1";
import TestPage2 from "./containers/Pages/TestPage/TestPage2";
import TestPage3 from "./containers/Pages/TestPage/TestPage3";
import Test from "./containers/Pages/TestPage/Test";
import Test2 from "./containers/Pages/TestPage/TestPage2";
import SignageOutputScreen from './containers/Pages/SignageOutputScreen/Screens'
import CanvasTest from './containers/Pages/TextTransition/Test'


const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("./containers/PublicPages/PublicHome/PublicHome")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import("./containers/PublicPages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN_REDIRECTED,
    component: lazy(() => import("./containers/Pages/SignIn/SignInRedirected")),
  },  
  {
    path: PUBLIC_ROUTE.REGISTRATION,
    component: lazy(() => import("./containers/PublicPages/Registration/Registration")),
  },
  {
    path: PUBLIC_ROUTE.PRIVACY,
    component: lazy(() => import("./containers/PublicPages/PublicHome/PrivacyPolicy")),
  },

  {
    path: PUBLIC_ROUTE.CONTACT,
    component: lazy(() => import("./containers/PublicPages/Contact/Contact")),
  },
  {
    path: PUBLIC_ROUTE.CONTACT,
    component: lazy(() => import("./containers/PublicPages/Demo/Demo")),
  },
];

const privateRoutes = [
  {
    path: PRIVATE_ROUTE.HOME,
    exact: true,
    component: lazy(() => import("./containers/Pages/Home/Home")),
  },
  // {
  //   path: PRIVATE_ROUTE.CANVAS,
  //   exact: true,
  //   component: lazy(() => import("./containers/Pages/CanvasMainPage/Canvas/Canvas")),
  // },
];


export default function Routes() {

  return (
    <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <Switch>
        {publicRoutes.map((route, index) => {
          return (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          );
        })} 
        {privateRoutes.map((route, index) => {
          return (
            <Route key={index} path={route.path}>
              <PrivateRoute>
                <route.component />
              </PrivateRoute>
            </Route>
          );
        })}

        <Route path='/screens/:id'>
          <SignageOutputScreen />
        </Route>
        <Route path='/test'>
          <Test />

        </Route>
        <Route path='/testcanvas'>
          <CanvasTest />

        </Route>
        <Route path='/test2'>
          <Test2 />
        </Route>
        <Route path='/test1'>
          <TestPage1 />
        </Route>
        <Route path='/test2'>
          <TestPage2 />
        </Route>
        <Route path='/test3'>
          <TestPage3 />
        </Route>

        <Route path='*'>
          <Error404 />
        </Route>
       
      </Switch>
    </Suspense>
  </ErrorBoundary>
  );
}
