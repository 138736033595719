import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: null,
  tenant :null
};

const isAuthenticated = createSlice({
  name: "isAuth",
  initialState,
  reducers: {
    isAuth(state, action) {
      state.isAuth = true;
     
    },
    isNotAuth(state) {
      state.isAuth = false;
      localStorage.clear();
    },
  },
});

export const { isAuth, isNotAuth } = isAuthenticated.actions;
export default isAuthenticated.reducer;
