import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const canvasSizeDetailsUpdateSlice = createSlice({
  name: "canvasDetails",
  initialState,
  reducers: {
    added(state, action) {
      return action.payload;
    },
   
  },
});

export const { added} =
canvasSizeDetailsUpdateSlice.actions;
export default canvasSizeDetailsUpdateSlice.reducer;
