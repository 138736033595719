import React, { useState, useEffect, useRef } from "react";
import "./Screens.css";
import "animate.css";
import 'antd/dist/antd.css';
import { baseURL } from "../../../baseURL";

export default function AnimatedComponent(props) {
  const focusPoint = useRef();
  const [selected, setSelected] = useState("fadeIn");
  // Options are "fadeInRight" , "fadeInRight" "fadeOutRight" "fadeOutLeft" "fadeInUp" "fadeOutup" "fadeInDown" "fadeOutDown" "zoomIn" "zoomOut"
  const [media, setMedia] = useState({url:baseURL + 'media' + props.nowplaying.file_url+'#t='+props.nowplaying.track_time, type:props.nowplaying.file_type});
  const [webSocketUrl, setWebSocketUrl] = useState(baseURL.replace('http:', 'ws:')+'ws/chat/'+String(props.templateId)+'_'+String(props.divId)+'/')

  useEffect(()=>{
    console.log("From use effect ", media)
    changeImgHandler(media)
    const chatSocket = new WebSocket(webSocketUrl);
    chatSocket.onmessage = function(e) {
      const data = JSON.parse(e.data);
      const url = baseURL.slice(0, -1) + data.url
      const temp = {
        type: data.file_type,
        url: url
      }
      changeImgHandler(temp)
    };
    chatSocket.onclose = function(e) {
      console.error('Chat socket closed unexpectedly');
    };
  },[])
  
  const changeImgHandler = (temp) => {
    setTimeout(function () {
      setMedia(temp);
    }, 1000);
  };
  return (
    <div>
      <div>
        {media.type === "image" ? (
          <img
            ref={focusPoint}
            className={`content-img animate__animated animate__${selected}`}
            style={{
              animationDuration: "1s",
              animationFillMode: "both",
            }}
            src={media.url}
            alt=''></img>
        ) : (
          <>
            <video
             style={{
              animationDuration: "2s",
              animationFillMode: "both",
            }}
              className={`content-img animate__animated animate__${selected}`}
              height='100%'
              autoPlay>
              <source src={media.url} type='video/mp4' autoPlay muted/>
            </video>
          </>
        )}
      </div>
    </div>
  );
}
