import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const userDetailsUpdateSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    added(state, action) {
      return action.payload;
    },
    addUserDetails: (state, action) => {
      return state.concat(action.payload);
    },
    updateUserDetails: (state, action) => {
      console.log("updateUserDetails", action.payload);
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteUserDetails: (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    },
  },
});

export const { added, updateUserDetails, deleteUserDetails,addUserDetails } =
  userDetailsUpdateSlice.actions;
export default userDetailsUpdateSlice.reducer;
