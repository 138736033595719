import React, {useRef} from 'react'

export default function Test() {
  const t = useRef()
  return (
    <div>
      <input ref = {t}></input>
      <button
      onClick={ 
        ()=>{
          t.current.focus()
        }
      }>Cick</button>
    </div>
  )
}
