import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import { isAuth, isNotAuth } from "./store/isAuthenticated";
import Loader from "./component/utility/loader";
import { useDispatch, useSelector } from "react-redux";
import { addProfileDetails } from "./store/profileDetails";
import avatar from "./assets/images/default.png";
import AbaciLoader from "./component/AbaciLoader/AbaciLoader";
import { baseURL } from "./baseURL";

export default function PrivateRoute(props) {
const [isLoggedIn, setIsLoggedIn] = useState(null);
const dispatch = useDispatch();
// const isAuth = useSelector(state => state.isAuth.isAuth)
const [toggle, setToggle] = useState(false)
// const productionURL = 'http://subdomain.abacisignage.com'
// const productionURLPublic = 'http://abacisignage.com'
// const developmentURL = 'http://subdomain.localhost:8000'
// const developmentURLPublic = 'http://localhost:8000'
  useEffect(() => {
    const access_token = localStorage.getItem('access_token')
    if(access_token){
      const tenant = localStorage.getItem('tenant')
      if(tenant){
        const url = "api/users/profile/";
        axios
        .get(url)
        .then((response) => {
          // alert('success')
          console.log(response.data)
          let profile = response.data
          if (profile.avatar === null) {
            profile = { ...response.data, avatar: avatar };
          }
          setIsLoggedIn('loggedIn')
          dispatch(addProfileDetails(profile));
          dispatch(isAuth());
        })
        .catch(error => {
          console.log(error)
          try {if(error.response.status === 403){
            alert(403)
            loggOffFunc()
          }
          else{

            setTimeout(()=>setToggle(state => !state), 2000)
          }}
          catch{
            loggOffFunc()
          }
        })
      }
      else{
        loggOffFunc()
      }
    }
    else{
      loggOffFunc()
    }
  }, [toggle])

  const loggOffFunc = () =>{
    setIsLoggedIn('loggedOff')
    dispatch(isNotAuth())
    localStorage.clear()
  }



  return (
    <>
    {isLoggedIn === null ?
    <AbaciLoader /> :
    isLoggedIn === "loggedOff"?
    <Redirect to='/signin' />:
    isLoggedIn === "loggedIn"?
    <>{props.children}</>:
    <AbaciLoader />
    }
    </>
    );
}
