// URL Set for Danish
export const productionURL = "https://subdomain.abacisignage.com";
export const productionURLPublic = "https://abacisignage.com";
export const developmentURL = "http://subdomain.localhost:8000";
export const developmentURLPublic = "http://localhost:8000";

// // // URL Set for Meethu
// export const productionURL = "https://subdomain.abacisignage.com";
// export const productionURLPublic = "https://abacisignage.com";
// export const developmentURL = productionURL;
// export const developmentURLPublic = productionURLPublic;

const getBaseURL = () => {
  let url = null;
  const tenant = localStorage.getItem("tenant");
  if (tenant === null) {
    return null;
  } else {
    if (process.env.NODE_ENV === "development") {
      url = developmentURL.replace("subdomain", tenant);
    } else {
      url = productionURL.replace("subdomain", tenant);
    }
    return url;
  }
};
export const baseURL = getBaseURL();

const getBaseURLPublic = () => {
  let url = null;
  if (process.env.NODE_ENV === "development") {
    url = developmentURLPublic;
  } else {
    url = productionURLPublic;
  }
  return url;
};
export const baseURLPublic = getBaseURLPublic();

const getBaseURLRaw = () => {
  let url = null;
  if (process.env.NODE_ENV === "development") {
    url = developmentURL;
  } else {
    url = productionURL;
  }
  return url;
};
export const baseURLRaw = getBaseURLRaw();
export const storageBucket = "s3";
// export const storageBucket='local';
